import React from 'react';
import { useState, useEffect } from "react";
import {db} from '../firebase';
import {
    collection,
    onSnapshot,
    query,
    orderBy,
    getDocs,
    updateDoc,
    doc,
    startAfter,
    limit
  } from "firebase/firestore";
import './DataStyle.css'
import { InputGroup, Form } from 'react-bootstrap';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CSVLink } from "react-csv";
import { useAuth } from '../context/AuthContext';







const Usuarios = () => {



    const [avaliacao, setAvalia] = useState([]);
    const [loader, setLoader] = useState(true);
    const [isAdmin, setIsAdmin] = useState("");
    const [editBox, setEditBox] = useState(false);
    const {currentUser} = useAuth();
    const usersCollectionRef = collection(db, "users");


    const makeAdmin = async (id, size) => {
        const productDoc = doc(db, "users", id);
    
        await updateDoc(productDoc, {
          admin: 1,
        });
        window.location.reload();
      };


      const deletAdmin = async (id, size) => {
        const productDoc = doc(db, "users", id);
    
        await updateDoc(productDoc, {
          admin: 0,
        });
        window.location.reload();
      };

  
    useEffect(() => {
      const getAvalia = async () => {
        const data = await getDocs(usersCollectionRef);
        // console.log(avaliacao);
        setAvalia(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        setLoader(false)
      }; 
      getAvalia();
     },[]
    );


  


console.log(currentUser.uid)

  

  
    return (

    

      <>
       

<TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto'}}>

  
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{fontWeight: '900' }}>Index</TableCell>
              <TableCell style={{fontWeight: '900' }}>E-mail</TableCell>
              <TableCell style={{fontWeight: '900' }}>Administrador</TableCell>
              <TableCell style={{fontWeight: '900' }}>Controle</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {loader === false && avaliacao.map((dev, index) => {

        // if (dev.admin === 1) { dev.admin = 'Sim' }
        // if (dev.admin === 0) { dev.admin = 'Não' }
        
  
          return ( 
            <TableRow key={index} className='hover'>
              <TableCell>{index}</TableCell>
              <TableCell>{dev.email}</TableCell>
              <TableCell>{dev.admin === 1 ? 'Sim' : 'Não'}</TableCell>
              <TableCell>
              {(dev.admin === 0 && dev.id !== currentUser.uid ) &&
                <button className='btn btn-secondary'
                   
                    onClick={() => {
                    makeAdmin(dev.id, dev.admin);
                    }}
                    
                >
                    make Admin
                </button>
            }
            {(dev.admin === 1 && dev.id !== currentUser.uid ) &&
                <button className='btn btn-secondary'
                   
                    onClick={() => {
                    deletAdmin(dev.id, dev.admin);
                    }}
                    
                >
                    Delet Admin
                </button>
            }
              </TableCell>
            </TableRow>
        );        
      })}
          </TableBody>
          
            

          
        </Table>
      </TableContainer>



       
    </>
    )
  
 
}

export default Usuarios