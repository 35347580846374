import React from 'react';
import { useState, useEffect } from "react";
import {db} from '../firebase';
import {
    collection,
    onSnapshot,
    query,
    orderBy,
    getDocs,
    startAfter,
    limit
  } from "firebase/firestore";
import './DataStyle.css'
import { InputGroup, Form } from 'react-bootstrap';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CSVLink } from "react-csv";







const Avaliacao = () => {

  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const colRof = query(collection(db, 'avaliacao'), orderBy('CreatedAt', "desc"));
  const [avaliacaoo, setAvaliao] = useState([]);
  const [lastDoc, setlastDoc] = useState();









  const getDtata = async () => {
    onSnapshot(colRof, (snapshot) => {
      let avaliacao = []
      snapshot.docs.forEach((doc) => {
        avaliacao.push({...doc.data(), id: doc.id})
      })
      setData(avaliacao);
      setLoader(false)
      
    })
  }


  

  useEffect(() => {
    getDtata()
    const q =query(collection(db, 'avaliacao'), orderBy('CreatedAt', "desc"), limit(10));
    const getAvalia = async () => {
      const data = await getDocs(q);
      setAvaliao(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      const lastDoc = data.docs[data.docs.length-1];
      setlastDoc(lastDoc)
    }; 
    
    getAvalia();
  }, [])


  const more = () => {
    const q =query(collection(db, 'avaliacao'), orderBy('CreatedAt', "desc"), startAfter(lastDoc), limit(10));
    const getAvalia = async () => {
      const data = await getDocs(q);
      const ForPagination = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setAvaliao((avaliacaoo) => [...avaliacaoo, ...ForPagination]);
      const lastDoc = data.docs[data.docs.length-1];
      setlastDoc(lastDoc)
    }; 
    getAvalia();
  }

  



const headers = [
  {label: 'Armazem_BH', key: 'Armazem_BH'},
  {label: 'Atendimento', key: 'Atendimento'},
  {label: 'Preco', key: 'Preco'},
  {label: 'Nome', key: 'Nome'},
  {label: 'Celular', key: 'Celular'},
  {label: 'Sugestao', key: 'Sugestao'},
  {label: 'Data', key: 'CreatedAtExcel'}
];




  

if(avaliacaoo.length === 0)
{
  return <h1>Loading...</h1>
}
  
  
    return (

    

      <>

<CSVLink filename='avaliação.csv' shit='avaliação' data={data} headers={headers}>
  Baixar o arquivo
</CSVLink>         

{/* <button onClick={onDownload}>Baixar</button> */}
<TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto'}}>

{/*   
  <Form>
   <InputGroup className='my-3'>
     <Form.Control onChange={(e) => setSearch(e.target.value)} placeholder='Buscar'/>
   </InputGroup>
  </Form> */}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{fontWeight: '900' }}>Index</TableCell>
              <TableCell style={{fontWeight: '900' }}>Armazem BH</TableCell>
              <TableCell style={{fontWeight: '900' }}>Atendimento</TableCell>
              <TableCell style={{fontWeight: '900' }}>Preço</TableCell>
              <TableCell style={{fontWeight: '900' }}>Nome</TableCell>
              <TableCell style={{fontWeight: '900' }}>Celular</TableCell>
              <TableCell style={{fontWeight: '900' }}>Sugestão</TableCell>
              <TableCell style={{fontWeight: '900' }}>Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {loader === false && avaliacaoo.filter((dev) => {
        return search.toLowerCase() === '' ? dev : dev.Armazem_BH.toLowerCase().includes(search.toLocaleLowerCase())
                                                  || dev.Atendimento.toLowerCase().includes(search.toLocaleLowerCase())
                                                  || dev.Preco.toLowerCase().includes(search.toLocaleLowerCase())
                                                  || dev.Nome.toLowerCase().includes(search.toLocaleLowerCase())
                                                  || dev.Celular.toLowerCase().includes(search.toLocaleLowerCase())
                                                  || dev.Sugestao.toLowerCase().includes(search.toLocaleLowerCase())
                                                  || moment(dev.CreatedAt.toDate().toString()).format(' D/M/YYYY - h:mm:ss a').toLowerCase().includes(search.toLocaleLowerCase());
      }).map((dev, index) => {
  
          return ( 
            <TableRow key={index} className='hover'>
              <TableCell>{index}</TableCell>
              <TableCell>{dev.Armazem_BH}</TableCell>
              <TableCell>{dev.Atendimento}</TableCell>
              <TableCell>{dev.Preco}</TableCell>
              <TableCell>{dev.Nome}</TableCell>
              <TableCell>{dev.Celular}</TableCell>
              <TableCell className='td'>{dev.Sugestao}</TableCell>
              <TableCell>{moment(dev.CreatedAt.toDate().toString()).format(' D/M/YYYY - h:mm:ss a')}</TableCell>
            </TableRow>
        );        
      })}
          </TableBody>
          <TableFooter  className='text-center' >
          {(data.length > 0 && data.length !== avaliacaoo.length) && (
            
              <TableRow className='text-center'>
                <TableCell className='text-center'> </TableCell>
                <TableCell className='text-center'> </TableCell>
                <TableCell className='text-center'> </TableCell>
                <TableCell className='text-center' colSpan={3}>
                <button className='btn btn-secondary' onClick={more}>Carregue mais</button>
                </TableCell>
                <TableCell className='text-center'> </TableCell>
                <TableCell className='text-center'> </TableCell>
                <TableCell className='text-center'> </TableCell>
              </TableRow>
            )}
            
          </TableFooter>

          
        </Table>
      </TableContainer>



       
    </>
    )
  
 
}

export default Avaliacao