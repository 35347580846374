import React, {useRef, useState} from 'react';
import {Button, Card, Form, Alert} from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';
import {useNavigate} from 'react-router-dom';
import { collection } from 'firebase/firestore';
import db from '../firebase';

const Signup = () => {
    const {signup} = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const emailRef = useRef();
    const passwordlRef = useRef();
    const passwordConfirlRef = useRef();
    const navigate = useNavigate();
    const handleSubmit = async(e) => {
        e.preventDefault();
        if (passwordlRef.current.value !== passwordConfirlRef.current.value)
        {
            return setError('a senha não corresponde');
        }
        
        try{
            setError('');
            setLoading(true);
            await signup(emailRef.current.value, passwordlRef.current.value);
            navigate('/');
        } catch{
            setError('falha ao criar uma conta');
        }
        setLoading(false);
    };
  return (
    <>
        <Card>
            <Card.Body>
                <h2 className='text-center mb-4'>Cadastrar</h2>
                {error && <Alert variant='danger'>{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label htmlFor='email'>E-mail</Form.Label>
                        <Form.Control type='email' id='email' ref={emailRef} required />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label htmlFor='senha'>Senha</Form.Label>
                        <Form.Control type='password' id='senha' ref={passwordlRef} required />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label htmlFor='consenha'>confirmação de senha</Form.Label>
                        <Form.Control type='password' id='consenha' ref={passwordConfirlRef} required />
                    </Form.Group>

                    <Button variant='primary' type='submit' className='w-100 mt-3' disabled = {loading}>
                        Cadastrar
                    </Button>
                </Form>
            </Card.Body>
        </Card>

        {/* <div className='w-100 text-center mt-2'>

        </div> */}
    </>
  )
}

export default Signup;