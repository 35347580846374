import { createContext, useContext, useEffect, useState } from "react";
import {createUserWithEmailAndPassword, 
    onAuthStateChanged, 
    signOut, 
    signInWithEmailAndPassword,
    sendPasswordResetEmail, 
    updateEmail,
    updatePassword} from 'firebase/auth';
import auth from '../firebase';
import {getFirestore} from 'firebase/firestore';
import {doc, setDoc, getDoc} from 'firebase/firestore';
import { Navigate, useLocation } from "react-router-dom";
import { useNavigate, Outlet  } from "react-router-dom";




const AuthContext = createContext();


const AuthProvider = ({children}) => {
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);
    const [userF, setUser] = useState();
    const navigate = useNavigate();
    // const signup = (email, password)=> {
    //     return createUserWithEmailAndPassword(auth, email, password)
    // };

 

    const signup = async (email, password) => {
        try {
          const res = await createUserWithEmailAndPassword(auth, email, password);
          const user = res.user.uid;
          const db1 = getFirestore();
          const docRef = doc(db1, "users", user);
          await setDoc(docRef, {
            uid: user,
            email,
            admin: 0
          }); 
        } catch (err) {
        //   console.error(err);
        //   alert(err);
        }
      };
    

    const logout = () => {
        return signOut(auth);
    };
    const login = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password);
    };
    const resetPassword = (email) => {
        return sendPasswordResetEmail(auth, email);
    };
    const updateUserEmail = (email) => {
        return updateEmail(auth.currentUser, email);
    };
    const updateUserPassword = (password) => {
        return updatePassword(auth.currentUser, password);
    };






    async function getRol(uid)
    {
        const db1 = getFirestore();
        const docuRef = await doc(db1, `users/${uid}`);
        const docu = await getDoc(docuRef);
        const infoFinal = await docu.data().admin;
        // console.log(infoFinal)
        return infoFinal;
    }

    
    async function setUserWithFirebaseAndAdmin(user)
    {
        await getRol(user.uid).then((admin) => {
            const userData = admin;
            setUser(userData);
            setLoading(false);               
        });
        
        
        // getRol(user.uid).then((admin) => {
        //     // const userData = {
        //     //     uid : user.uid,
        //     //     email : user.email,
        //     //     admin: admin,
        //     // };
        //     const userData = admin
        //     setUser(userData)
        //     // console.log(userData)
        //     // if (userData === 1)
        //     // {
        //     //     setLoading(false);
        //     //     // console.log(userData)
        //     // }
        //     // else
        //     // {
        //     //     // navigate("/error");
        //     // }         
        // }); 
    }

    



    

    useEffect(() => {

        
           
        const unSubscribe = onAuthStateChanged(auth, (user) => {
            // getRol(auth.uid).then((admin) => {
            //     const userData = admin
            //     setUser(userData)       
            // }); 
            // console.log('545454',userF) 
            setCurrentUser(user);            
            // setLoading(false);

            
            
            if (user)
            {   
                
                if (!userF)
                { 
                    setLoading(true);
                    setUserWithFirebaseAndAdmin(user);
                    
                }           
            }
            else
            {
                setUser()
                setLoading(false);
            }           
            
        });
        return () => {
            unSubscribe();
        };
    } , []);
    return (
        <AuthContext.Provider value={{currentUser, signup, logout, login, resetPassword, updateUserEmail,
            updateUserPassword, 
            setUserWithFirebaseAndAdmin, 
            getRol, userF, setUser}}>
            {!loading && children}
        </AuthContext.Provider>
    )
}

export default AuthProvider

export const useAuth = () => {
    return useContext(AuthContext);
}
