import React, {useRef, useState}  from 'react'
import {Button, Card, Form, Alert} from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';
import {Link} from 'react-router-dom';

const ForgetPassword = () => {
  const {resetPassword} = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const emailRef = useRef();
  const [message, setMessage] = useState('');
  const handleSubmit = async(e) => {
      e.preventDefault();
      
      try{
          setError('');
          setLoading(true);
          await resetPassword(emailRef.current.value);
          setMessage("Check your inbox");
      } catch{
          setError('falha enviar e-mail');
      }
      setLoading(false);
  };
  return (
    <>
        <Card>
            <Card.Body>
                <h2 className='text-center mb-4'>Recuperação de senha</h2>
                {error && <Alert variant='danger'>{error}</Alert>}
                {message && <Alert variant='success'>{message}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label htmlFor='email'>E-mail</Form.Label>
                        <Form.Control type='email' id='email' ref={emailRef} />
                    </Form.Group>


                    <Button variant='primary' type='submit' className='w-100 mt-3' disabled = {loading}>
                      Recuperação de senha
                    </Button>
                </Form>
            </Card.Body>
        </Card>
                <div className='w-100 text-center mt-3'>
                  <Link to='/login'>Entrar!</Link>
                </div>

    </>
  )
}

export default ForgetPassword;