import './App.css';
import React, {useEffect} from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {Container} from 'react-bootstrap';
import Signup from './components/Signup';
import Login from './components/Login';
// import Getdata from './components/Getdata';
// import Getdatatwo from './components/Getdatatwo';
import ForgetPassword from './components/ForgetPassword';
// import UpdateEmail from './components/UpdateEmail';
import UpdateSenha from './components/UpdateSenha';
// import Dashboard from './components/Dashboard';
import NoMach from './components/NoMach';
import AuthProvider from './context/AuthContext';
import RequireAuth from './context/RequireAuth';
import NotRequireAuth from './context/NotRequireAuth';
import Header from './components/header/Header';
import Avaliacao from './components/Avaliacao';
import Usuarios from './components/Usuarios'



function App() {
  useEffect(() => {
    document.title='CCPR';
  });
  return (
    
        <Router>
           
          <AuthProvider>
            <Header/>
            <Container>
            
              <Routes>
                <Route path='/signup' element={<NotRequireAuth><Signup /></NotRequireAuth>}/>
                <Route path='/login' element={<NotRequireAuth><Login /></NotRequireAuth>}/>
                <Route path='/forget-password' element={<ForgetPassword />}/>
                {/* <Route path='/update-email' element={<RequireAuth><UpdateEmail /></RequireAuth>}/> */}
                <Route path='/update-senha' element={<RequireAuth><UpdateSenha /></RequireAuth>}/>
                <Route path='/usuarios' element={<RequireAuth><Usuarios /></RequireAuth>}/>
                {/* <Route path='/getdata' element={<Getdata />}/> */}
                {/* <Route path='/getdatatwo' element={<Getdatatwo />}/> */}
                <Route path='/' element={<RequireAuth><Avaliacao /></RequireAuth>}/>
                {/* <Route path='/' element={<RequireAuth><Dashboard /></RequireAuth>}/> */}
                <Route path='*' element={<NoMach />}/>
              </Routes>
            </Container>
          </AuthProvider>
        </Router>
  );
  

};

export default App;
