import React, {useRef, useState} from 'react';
import {Button, Card, Form, Alert} from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';
import {Link, useNavigate} from 'react-router-dom';

const UpdateSenha = () => {
  const {currentUser, updateUserEmail, updateUserPassword} = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const passwordlRef = useRef();
  const passwordConfirlRef = useRef();
  const navigate = useNavigate();
  const handleSubmit = (e) => {
      e.preventDefault();
      if (passwordlRef.current.value !== passwordConfirlRef.current.value)
      {
          return setError('a senha não corresponde');
      }

      const promises = [];
      setLoading(true);
      setError('');
      

      if (passwordlRef.current.value)
      {
        promises.push(updateUserPassword(passwordlRef.current.value));
      }
      Promise.all(promises).then(() => {
        navigate('/');
      }).catch(() => {
        setError('falha ao atualizar');
      }).finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Card>
            <Card.Body>
                <h2 className='text-center mb-4'>Atualizar sua senha</h2>
                {error && <Alert variant='danger'>{error}</Alert>}
                <Form onSubmit={handleSubmit}>

                

                    <Form.Group>
                        <Form.Label htmlFor='senha'>Senha</Form.Label>
                        <Form.Control type='password' id='senha' ref={passwordlRef} />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label htmlFor='consenha'>confirmação de senha</Form.Label>
                        <Form.Control type='password' id='consenha' ref={passwordConfirlRef} />
                    </Form.Group>

                    <Button variant='primary' type='submit'  className='btn btn-secondary w-100 mt-3' disabled = {loading}>
                        Atualizar
                    </Button>
                </Form>
            </Card.Body>
        </Card>

        <div className='w-100 text-center mt-2'>
          <Link to='/'>Cancelar</Link>
        </div>
    </>
  )
}

export default UpdateSenha;