import React, {useRef, useState} from 'react';
import {Button, Card, Form, Alert} from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';
import {useNavigate, Link, useLocation} from 'react-router-dom';

const Login = () => {
  const {login} = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const emailRef = useRef();
  const passwordlRef = useRef();
  const navigate = useNavigate();
  const location = useLocation;
  const redirectPath = location.state?.path || '/' ;
  const handleSubmit = async(e) => {
      e.preventDefault();
      
      try{
          setError('');
          setLoading(true);
          await login(emailRef.current.value, passwordlRef.current.value);
          navigate(redirectPath, {replace:true});
      } catch{
          setError('falha ao entrar');
      }
      setLoading(false);
  };
  return (
    <>
        <Card>
            <Card.Body>
                <h2 className='text-center mb-4'>Entrar</h2>
                {error && <Alert variant='danger'>{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label htmlFor='email'>E-mail</Form.Label>
                        <Form.Control type='email' id='email'  ref={emailRef}/>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label htmlFor='senha'>Senha</Form.Label>
                        <Form.Control type='password' id='senha' ref={passwordlRef}/>
                    </Form.Group>


                    <Button variant='primary' type='submit' className='w-100 mt-3' disabled = {loading} >
                      Entrar
                    </Button>
                </Form>
                <div className='w-100 text-center mt-3'>
                  <Link to='/forget-password'>Esqueceu a senha!</Link>
                </div>
            </Card.Body>
        </Card>

    </>
  )
}

export default Login;