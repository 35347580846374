import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthContext";




const NotRequireAuth = ({children}) => {
    const {currentUser, userF} = useAuth();
    const location = useLocation();

    
    if (currentUser)
    {
      
       return <Navigate to={'/'} state={{path : location.pathname}} />
    }

      

  return children;
}

export default NotRequireAuth;