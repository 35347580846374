import React, { useState } from 'react'
import {Button, Alert} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './Style.css';
import logo from '../../images/ccpr.png';


const Header = () => {
  const {currentUser} = useAuth();
  const {logout} = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const handleLogout = async() => {
      setError('');
      try
      {
        await logout();
        navigate('/login');
      } catch
      {
        setError('falha ao sair');
      }
    };

      

  return (
    <header>
      <Navbar style={{background: '#045563!important'}} collapseOnSelect expand="lg"  variant="dark">
      <Container>
        <Navbar.Brand href="/"><img src={logo} alt="CCPR"  /></Navbar.Brand>
        
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
        {(currentUser !== null) &&
          <Nav className="me-auto">
            <Nav.Link href="/">Avaliação</Nav.Link>
            <Nav.Link href="/usuarios">Usuários</Nav.Link>
            
          </Nav>
          }
          <Nav>
            {(currentUser !== null) &&
            <NavDropdown title={
              'ola '  + currentUser && 'Olá ' + currentUser.email
              } id="collasible-nav-dropdown">
          
              <NavDropdown.Item href="/update-senha">
              Atualizar sua senha
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item>
                <Button style={{color: "#212529", background: "transparent", border: "none",}} onClick={handleLogout}>Sair</Button>
              </NavDropdown.Item>
            </NavDropdown>
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

      <h2>{error && <Alert variant='danger'>{error}</Alert>}</h2>
    </header>
  )
}

export default Header